<template>
  <div class="ChatStart__container">
    <v-col>
      <div>
        <div class="SessionsPage__sessions">
          <div class="titleWrapper">
          <span class="title">
          <v-icon small> mdi-forum-outline </v-icon> Your recent chats
          </span>
          </div>
          <div class="list">
            <div class="item" v-for="session in sessions" :key="session.id" @click="goToSession(session.id)">
            <span v-if="session?.workspace?.name" class="workspace">
              <v-icon small> mdi-account-group-outline </v-icon> {{ session?.workspace?.name }}
            </span>
              <v-icon small v-if="!session?.workspace?.name"> mdi-forum-outline </v-icon>
              <span class="sessionTitle">
              {{ session.name }}
            </span>
              <span class="recentActivity">
              {{ getTime(session.updatedAt) }}
            </span>
            </div>
          </div>
          <v-btn class="textButton" @click.stop="loadMoreSessions()" :loading="sessionsListLoading" v-if="canLoadMoreSession">
            Load more
          </v-btn>
        </div>
      </div>
    </v-col>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
    mapMutations: AIChatMutations,
} = createNamespacedHelpers('AIChat');

/*import debounce from "lodash/debounce";

import Loader from "@/components/loader/loader.vue";
import lodashGet from "lodash/get";*/
import constants from "@/util/constants";
import * as moment from "moment/moment";

export default {
  name: 'ChatStartPage',
  data() {
    return {
      sessionPage: 0,
      message: '',
      files: [],
      filesAccumulated: [],
      publicPath: constants.isDevelopment ? "" : "",
      canLoadMoreSession: true,
    }
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    ...AIChatGetters({
      sendMessageLoading: 'SEND_MESSAGE_LOADING',
      sessions: 'SESSIONS_LIST',
      sessionsListLoading: 'SESSIONS_LIST_LOADING'
    }),
  },
  methods: {
    ...AIChatActions(['sendMessage', 'getSessions']),
    ...AIChatMutations({
      setSessions: 'SET_SESSIONS_LIST'
    }),
    getTime(date) {
      return moment(date).fromNow()
    },
    async loadMoreSessions() {
      this.sessionPage += 1;
      const preLoadCount = this.sessions.length;
      await this.getSessions({page: this.sessionPage, push: true})
      const postLoad = this.sessions.length;
      if (postLoad === preLoadCount) {
        this.canLoadMoreSession = false;
      }
    },
    async getInfo() {
      await this.getSessions({ page: this.sessionPage })
    },
    goToSession(id) {
      this.$router.push({ path: `/ai-chat`, query: { session: id } });
    }
  },
  watch: {
    async 'selectedCustomer.customer_id'() {
      this.setSessions([])
      await this.getInfo();
    },
  },
  async mounted() {
    await this.getInfo();
  }
};
</script>

<style lang="scss" scoped>
@import './sessions';
</style>
